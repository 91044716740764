const accordeon = () => {
	var accordion = Array.from(document.querySelectorAll('[rel=accordion]'));
	// console.log(accordion);
	accordion.forEach(function(element) {
		var accTitle = Array.from(element.querySelectorAll('.accordion-title'));
		var accItems = Array.from(element.querySelectorAll('.accordion-item'));
		accTitle.forEach(function(title, index) {
			title.addEventListener('click', function() {
				var parent = title.parentElement;
				if (!parent.classList.contains('open')) {
					accItems.forEach(function(item) {
						item.classList.remove('open');
					});
				}
				parent.classList.toggle('open');
			});
		});
	});
};

accordeon();
